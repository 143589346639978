import Platforms from './bodegon_cabecera.png'
import Laptop from './panel_de_control.png'
import Maps from './bodegon_juegos.png'
import Tablet from './tablet_retos.png'
import Banner from './modulo_escenarios.gif'
import Teachers from './profesores.png'
import CEU from './logo_CEU.png'
import Foundation from './logo_fundacion_villacisneros.png'

const Images =  {
    Platforms,
    Laptop,
    Maps,
    Tablet,
    Banner,
    Teachers,
    CEU,
    Foundation
}

export default Images