import {Fragment, useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import './header.scss'

import HamburgerBtn from "../HamburgerBtn";

import Logo from './logo-blanco-mini/logo-blanco-mini.png'
import {Link} from "react-router-dom";

const Header = ({menu = []}) => {
    const [open, set] = useState(false)

    const {opacity, transform} = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? 'translate(0%, 0)' : 'translate(-100%, 0)',
    })

    useEffect(() => {
        const updateWindowDimensions = () => {
            set(false)
        }
        window.addEventListener('resize', updateWindowDimensions)
        return () => window.removeEventListener('resize', updateWindowDimensions)
    }, [])

    return <div className={'main-header-styles'}>
        <div className="overlay"  >
            <animated.div style={{opacity, display: !open ? 'none' : 'block'}}  onClick={() => set(false)}/>
            <animated.div className={'menu'} style={{opacity, transform}} >
                <img src={Logo} alt="logo" />
                <div>
                    {
                        menu.map((item, i) => <Fragment key={i}>{item}</Fragment>)
                    }
                </div>
            </animated.div >
        </div>
        <div className="container">
            <Link to={'/'}><img src={Logo} alt="logo" /></Link>
            <div>
                {
                    menu.map((item, i) => <Fragment key={i}>{item}</Fragment>)
                }
            </div>
            <div>
                {
                    menu.length && <HamburgerBtn pressed={open} onclick={set} />
                }
            </div>
        </div>
    </div>
}

export default Header;