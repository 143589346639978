import './card.scss'

const Card = ({img, name, school, plus}) => {
    return <div className={'card-main-styles'}>
        <div>
            <div>
                <img src={img} alt={'Person1'}/>
            </div>
            <h4>
                {name}
                <span>{school}</span>
            </h4>
            <div>
                <ul>
                    {
                        plus.map((item, i) => <li key={i}>{item}</li>)
                    }
                </ul>
            </div>
        </div>
    </div>
}

export default Card