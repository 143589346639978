import {Fragment} from "react";

import Header from "../../components/Header";

const Privacy = () => {
    return <Fragment>
        <Header />
        <div className="content wrapper-main">
            <div className={'wrapper-main'}>
                <div>
                    <p><strong>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</strong></p>
                    <p><strong>1 IDENTIFICACI&Oacute;N</strong></p>
                    <ul>
                        <li>Identidad:<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> (en adelante, &ldquo;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>&rdquo;)</li>
                        <li>C/ Montalb&aacute;n, 7 4&ordm; 28014. Madrid</li>
                        <li>NIF: G-85139194</li>
                        <li>Delegado de Protecci&oacute;n de Datos (DPO): puede contactar con nuestro DPO a trav&eacute;s de los siguientes medios:&nbsp;</li>
                        <li>E-mail: info@fundaci&oacute;nvillacisneros.es&nbsp;</li>
                        <li>Direcci&oacute;n: C/ Montalb&aacute;n, 7 4&ordm; 28014. Madrid</li>
                    </ul>
                    <p>Indicando en la referencia &ldquo;Delegado de Protecci&oacute;n de Datos&rdquo;.</p>

                    <p><strong>2 INFORMACIÓN Y CONSENTIMIENTO.</strong></p>
                    <p>Mediante la aceptaci&oacute;n de la presente Pol&iacute;tica de Privacidad, el usuario queda informado y presta su consentimiento libre, informado, espec&iacute;fico e inequ&iacute;voco para que los datos personales que facilite a trav&eacute;s de la p&aacute;gina web ubicada en la URL <a href="https://eduhispania.es">https://eduhispania.es</a> (en adelante, el &ldquo;<strong>Sitio Web</strong>&rdquo;) sean tratados por <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>, as&iacute; como los datos derivados de su navegaci&oacute;n y aquellos otros datos que pueda facilitar en un futuro a <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>.</p>
                    <p>El usuario debe leer con atenci&oacute;n esta Pol&iacute;tica de Privacidad, que ha sido redactada de forma clara y sencilla, para facilitar su comprensi&oacute;n, determinando libre y voluntariamente si desea facilitar sus datos personales a <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>.</p>
                    <p>Si eres <strong>MENOR DE EDAD</strong>, s&oacute;lo puedes aportar datos personales con el previo consentimiento de padres o tutores, enviado debidamente firmado y con copia del DNI del padre o tutor firmante a la direcci&oacute;n&nbsp;info@fundaci&oacute;nvillacisneros.es. &nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> no responde de los datos de menores que sin poder conocer este hecho, se hayan facilitado sin consentimiento de padre o tutor.</p>
                    <p><strong>3 OBLIGATORIEDAD DE FACILITAR LOS DATOS.</strong></p>
                    <p>Los datos solicitados en los formularios del Sitio Web son con car&aacute;cter general, obligatorios (salvo que en el campo requerido se especifique lo contrario) para cumplir con las finalidades establecidas.</p>
                    <p>Por lo tanto, si no se facilitan los mismos o no se facilitan correctamente no podr&aacute;n atenderse las mismas, sin perjuicio de que podr&aacute; visualizar libremente el contenido del Sitio Web.</p>
                    <p><strong>4 &iquest;CON QU&Eacute; FINALIDAD TRATAR&Aacute;N FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU LOS DATOS PERSONALES DEL USUARIO?</strong></p>
                    <p>Los datos personales facilitados a trav&eacute;s del Sitio Web ser&aacute;n tratados por <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> conforme a las siguientes finalidades:</p>
                    <ol>
                        <li>Datos facilitados para el registro de la&nbsp;<strong>Plataforma</strong>:</li>
                    </ol>
                    <ul>
                        <li>Gestionar su registro como usuario en la plataforma online propiedad de FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU y alojada en el presente Sitio Web.</li>
                        <li>Gestionar el contenido del Portal.</li>
                        <li>Efectuar an&aacute;lisis sobre la utilizaci&oacute;n del Sitio Web y comprobar las preferencias y comportamiento de los usuarios.</li>
                    </ul>
                    <ol>
                        <li>Datos facilitados en los&nbsp;<strong>formularios de contacto y otros canales puestos a disposici&oacute;n de los usuarios</strong>:</li>
                    </ol>
                    <ul>
                        <li>Gestionar las solicitudes de contacto e informaci&oacute;n del usuario a trav&eacute;s de los canales dispuestos para ello en los sitios web de&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>.</li>
                        <li>Gesti&oacute;n de la petici&oacute;n planteada.</li>
                        <li>Efectuar an&aacute;lisis sobre la utilizaci&oacute;n del Sitio Web y comprobar las preferencias y comportamiento de los usuarios.</li>
                    </ul>
                    <p><strong>5 &iquest;QU&Eacute; DATOS DEL USUARIO TRATAR&Aacute; FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU?</strong></p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> tratar&aacute; las siguientes categor&iacute;as de datos del usuario:</p>
                    <ol>
                        <li>Datos facilitados para el registro de la&nbsp;<strong>Plataforma</strong>:</li>
                    </ol>
                    <ul>
                        <li>Datos identificativos: nombre, apellidos.</li>
                        <li>Otros datos: datos facilitados por los propios interesados en los campos abiertos. &nbsp;</li>
                        <li>Datos de navegaci&oacute;n.</li>
                    </ul>
                    <ol>
                        <li>Datos facilitados en los&nbsp;<strong>formularios de contacto y otros canales puestos a disposici&oacute;n de los usuarios</strong>:</li>
                    </ol>
                    <ul>
                        <li>Datos identificativos: nombre, apellidos.</li>
                        <li>Datos de contacto: direcci&oacute;n de correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono.</li>
                        <li>Datos de transacci&oacute;n de bienes y servicios.</li>
                        <li>Otros datos: datos facilitados por los propios interesados en los campos abiertos de los formularios dispuestos en el Sitio Web o en los documentos adjuntos. &nbsp;</li>
                        <li>Datos de navegaci&oacute;n.</li>
                    </ul>
                    <p>En caso de que el usuario facilite datos de terceros, manifiesta contar con el consentimiento de los mismos y se compromete a trasladarle la informaci&oacute;n contenida en la Pol&iacute;tica de Privacidad, eximiendo a <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> de cualquier responsabilidad en este sentido. No obstante, FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU podr&aacute; llevar a cabo las verificaciones peri&oacute;dicas para constatar este hecho, adoptando las medidas de diligencia debida que correspondan, conforme a la normativa de protecci&oacute;n de datos.</p>
                    <p><strong>6 &iquest;CU&Aacute;L ES LA LEGITIMACI&Oacute;N DEL TRATAMIENTO DE LOS DATOS DEL USUARIO?</strong></p>
                    <p>La legitimaci&oacute;n del tratamiento de sus datos personales, ser&aacute;n las siguientes:</p>
                    <ol>
                        <li>Datos facilitados para el registro de la <strong>Plataforma</strong>: el consentimiento que se le solicita y que puede retirar en cualquier momento. &nbsp;No obstante, en caso de retirar su consentimiento, ello no afectar&aacute; a la licitud de los tratamientos efectuados con anterioridad. Para la ejecuci&oacute;n de an&aacute;lisis sobre la utilizaci&oacute;n de la web, el inter&eacute;s leg&iacute;timo de&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>.</li>
                        <li>Para los <strong>formularios de contacto y otros canales puestos a disposici&oacute;n de los usuarios</strong>: el consentimiento que se le solicita y que puede retirar en cualquier momento. &nbsp;No obstante, en caso de retirar su consentimiento, ello no afectar&aacute; a la licitud de los tratamientos efectuados con anterioridad. Para la ejecuci&oacute;n de an&aacute;lisis sobre la utilizaci&oacute;n de la web, el inter&eacute;s leg&iacute;timo de&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>.</li>
                    </ol>
                    <p>Los consentimientos obtenidos para las finalidades mencionadas son independientes por lo que el usuario podr&aacute; revocar solo uno de ellos no afectando a los dem&aacute;s.</p>
                    <p>Para revocar dicho consentimiento, el Usuario podr&aacute; contactar con <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>, en cualquier momento, a trav&eacute;s de los canales siguientes:&nbsp;info@fundaci&oacute;nvillacisneros.es, indicando en el asunto de referencia &ldquo;Datos Personales&rdquo;.</p>
                    <p><strong>7 &iquest;CON QU&Eacute; DESTINATARIOS SE COMPARTIR&Aacute;N LOS DATOS DEL USUARIO?</strong></p>
                    <p>Los datos del usuario podr&aacute;n ser comunicados a:</p>
                    <ol>
                        <li>Datos facilitados para el registro de la <strong>Plataforma</strong>: Empresas del grupo al que pertenece&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>, &uacute;nicamente para fines administrativos internos y/o para las finalidades anteriormente indicadas.</li>
                        <li>Datos facilitados para los <strong>formularios de contacto y otros canales puestos a disposici&oacute;n de los usuarios</strong>: Empresas del grupo al que pertenece&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>, &uacute;nicamente para fines administrativos internos y/o para las finalidades anteriormente indicadas.</li>
                    </ol>
                    <p>Adicionalmente, los datos podr&aacute;n ser accesibles por proveedores de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>, siendo dicho acceso el necesario para el adecuado cumplimiento de las obligaciones legales y/o de las finalidades anteriormente indicadas. Dichos proveedores no tratar&aacute;n sus datos para finalidades propias que no hayan sido previamente informadas por <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong>.</p>
                    <p><strong>8 CONSERVACI&Oacute;N DE LOS DATOS</strong></p>
                    <p>Sus datos ser&aacute;n conservados durante los siguientes plazos:</p>
                    <ol>
                        <li>Datos facilitados para el registro de la&nbsp;<strong>Plataforma</strong>: ser&aacute;n conservados hasta que el usuario no solicite la baja de la misma y, una vez solicitada, durante el plazo de prescripci&oacute;n de acciones legales derivadas del uso de la misma.</li>
                        <li>Datos facilitados para los&nbsp;<strong>formularios de contacto y otros canales puestos a disposici&oacute;n de los usuarios</strong>: ser&aacute;n conservados durante el plazo necesario para dar tr&aacute;mite y contestaci&oacute;n a su solicitud y, una vez finalizado el mismo, durante el plazo de prescripci&oacute;n de las acciones legales derivadas de la citada solicitud.</li>
                    </ol>
                    <p><strong>9 RESPONSABILIDAD DEL USUARIO.</strong></p>
                    <p>El usuario:</p>
                    <ul>
                        <li>Garantiza que es mayor de dieciocho (18) a&ntilde;os y que los datos que facilita a&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU&nbsp;</strong>son verdaderos, exactos, completos y actualizados. A estos efectos, el usuario responde de la veracidad de todos los datos que comunique y mantendr&aacute; convenientemente actualizada la informaci&oacute;n facilitada, de tal forma que responda a su situaci&oacute;n real.</li>
                        <li>Garantiza que ha informado a los terceros de los que facilite sus datos, en caso de hacerlo, de los aspectos contenidos en este documento. Asimismo, garantiza que ha obtenido su autorizaci&oacute;n para facilitar sus datos a&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> para los fines se&ntilde;alados.</li>
                        <li>Ser&aacute; responsable de las informaciones falsas o inexactas que proporcione a trav&eacute;s del Sitio Web y de los da&ntilde;os y perjuicios, directos o indirectos, que ello cause a&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> o a terceros.</li>
                    </ul>
                    <p><strong>10 EJERCICIO DE DERECHOS.</strong></p>
                    <p>El usuario puede enviar un escrito a FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU, a la direcci&oacute;n indicada en el encabezado de la presente Pol&iacute;tica, o bien por medio de un correo electr&oacute;nico a la direcci&oacute;n info@fundaci&oacute;nvillacisneros.es, adjuntando fotocopia de su documento de identidad, en cualquier momento y de manera gratuita, para:</p>
                    <ul>
                        <li>Revocar los consentimientos otorgados.</li>
                        <li>Obtener confirmaci&oacute;n acerca de si en&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> se est&aacute;n tratando datos personales que conciernen al usuario o no.</li>
                        <li>Acceder a sus datos personales.</li>
                        <li>Rectificar los datos inexactos o incompletos.</li>
                        <li>Solicitar la supresi&oacute;n de sus datos cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</li>
                        <li>Obtener de&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> la limitaci&oacute;n del tratamiento de los datos cuando se cumpla alguna de las condiciones previstas en la normativa de protecci&oacute;n de datos.</li>
                        <li>Solicitar la portabilidad de los datos facilitados por el Usuario en aquellos casos previstos en la normativa.</li>
                        <li>Ponerse en contacto con el DPO de&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> a trav&eacute;s de la siguiente direcci&oacute;n: info@fundaci&oacute;nvillacisneros.es</li>
                        <li>Interponer una reclamaci&oacute;n relativa a la protecci&oacute;n de sus datos personales ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos en la direcci&oacute;n Calle de Jorge Juan, 6, 28001 Madrid, cuando el interesado considere que&nbsp;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU</strong> ha vulnerado los derechos que le son reconocidos por la normativa aplicable en protecci&oacute;n de datos.</li>
                    </ul>
                    <p><strong>11 MEDIDAS DE SEGURIDAD.</strong></p>
                    <p>FUNDACI&Oacute;N VILLACISNEROS y FUNDACION CEU tratar&aacute; los datos del usuario, en todo momento, de forma absolutamente confidencial y guardando el preceptivo deber de secreto respecto de los mismos, de conformidad con lo previsto en la normativa de aplicaci&oacute;n, adoptando al efecto las medidas de &iacute;ndole t&eacute;cnica y organizativas necesarias que garanticen la seguridad de sus datos y eviten su alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnolog&iacute;a, la naturaleza de los datos almacenados y los riesgos a que est&aacute;n expuestos.</p>
                    <p style={{textAlign: "right"}}><strong>&Uacute;ltima actualizaci&oacute;n: 7 de abril de 2022.</strong></p>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Privacy