import {animated, useSpring} from "react-spring";
import './button.scss'

const reddish = '#d1352f'
const defaultStyle = {
    scale: 1,
    color: 'white',
    boxShadow: '-1px 8px 10px 3px rgba(0,0,0,0.30)',
    background: reddish,
}

const Button = ({label, ghost, rounded, href, newTab, onPress}) => {
    const [style, api] = useSpring(
        () => defaultStyle
    )

    const onClick = () => {
        if (onPress) {
            onPress()
        }
        href && window.open(href, newTab ? "_blank" : "_self")
    }

    return <animated.div
        className={`
        main-button-styles 
        ${ghost && 'main-button-styles-ghost'} 
        ${rounded && 'main-button-styles-rounded'}
        `}
        onMouseEnter={() =>
            api.start({
                scale: 0.98,
                background: 'white',
                color: reddish,
                boxShadow: '-1px 5px 7px -1px rgba(0,0,0,0.69)',
            })
        }
        onMouseLeave={() => api.start(defaultStyle)}
        style={style}
        onClick={onClick}
    >
        {label}
    </animated.div>
}

export default Button