import {Fragment} from "react";

import Header from "../../components/Header";

const Legal = () => {
    return <Fragment>
        <Header />
        <div className="content wrapper-main">
            <div className={'wrapper-main'}>
                <div>
                    <p><strong>AVISO LEGAL</strong></p>
                    <p>En cumplimiento del art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la
                        Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico, a continuaci&oacute;n se
                        exponen los datos identificativos del titular del sitio web <a
                            href="https://eduhispania.es">https://eduhispania.es</a> (en adelante, el &ldquo;Sitio
                        Web&rdquo;):</p>
                    <p><strong>TITULARES:&nbsp;</strong>Fundaci&oacute;n Villacisneros y Fundaci&oacute;n CEU (en
                        adelante, &ldquo;<strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong>&rdquo;)
                    </p>
                    <p><strong>CIF:&nbsp;</strong></p>
                    <p><strong>Domicilio:</strong>&nbsp;</p>
                    <p>El acceso, navegaci&oacute;n y utilizaci&oacute;n del Sitio Web implica la aceptaci&oacute;n
                        expresa y sin reservas de todos los t&eacute;rminos de las presentes condiciones de uso,
                        teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado.</p>
                    <p>Su observancia y cumplimiento ser&aacute; exigible respecto de cualquier persona que acceda,
                        navegue o utilice la Web. Si Ud. no est&aacute; de acuerdo con los t&eacute;rminos expuestos, no
                        acceda, navegue o utilice ninguna p&aacute;gina del sitio web.</p>
                    <p><strong>CONDICIONES GENERALES DE USO</strong></p>
                    <p>Las presentes condiciones de uso regulan el acceso, navegaci&oacute;n y utilizaci&oacute;n del
                        Sitio Web, sin perjuicio que<strong>&nbsp;FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N
                            CEU</strong> se reservan el derecho a modificar la presentaci&oacute;n, configuraci&oacute;n
                        y contenido de la Web, as&iacute; como las condiciones requeridas para su acceso y/o
                        utilizaci&oacute;n. El acceso y utilizaci&oacute;n de los contenidos del Sitio Web tras la
                        entrada en vigor de sus modificaciones o cambios suponen la aceptaci&oacute;n de los mismos.</p>
                    <p>No obstante, el acceso a determinados contenidos y la utilizaci&oacute;n de determinados
                        servicios puede encontrarse sometido a determinadas condiciones particulares, que ser&aacute;n,
                        en todo caso, claramente mostradas y deber&aacute;n ser aceptadas expresamente por parte de los
                        usuarios. Estas condiciones particulares, podr&aacute;n sustituir, completar o, en su caso,
                        modificar las presentes condiciones de uso particulares.</p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>se reservan el
                        derecho a modificar los t&eacute;rminos y condiciones aqu&iacute; estipuladas, total o
                        parcialmente, publicando cualquier cambio en la misma forma en que aparecen estas condiciones de
                        uso o a trav&eacute;s de cualquier tipo de comunicaci&oacute;n dirigida a los usuarios.</p>
                    <p><strong>ACCESO Y SEGURIDAD</strong></p>
                    <p>Con car&aacute;cter general, los Usuarios podr&aacute;n acceder al sitio web de forma libre y
                        gratuita. No obstante, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> se
                        reservan conforme a la legislaci&oacute;n vigente el derecho de limitar el acceso a
                        determinadas &aacute;reas del sitio web para las cuales el Usuario deber&aacute; registrarse
                        facilitando toda la informaci&oacute;n solicitada, de forma actualizada y real (v&eacute;ase en
                        cada caso la pol&iacute;tica de privacidad).&nbsp;</p>
                    <p>Queda prohibido el acceso al Sitio Web por parte de menores de edad.</p>
                    <p>Bajo ning&uacute;n concepto <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N
                        CEU</strong> se responsabilizar&aacute; de la veracidad de los datos de registro facilitados por
                        los usuarios finales, por lo que cada uno de estos son los responsables de las posibles
                        consecuencias, errores y fallos que posteriormente puedan derivarse de la falta de veracidad de
                        los datos.</p>
                    <p><strong>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>son titulares o, en
                        su caso, cuenta con las licencias correspondientes sobre los derechos de explotaci&oacute;n de
                        propiedad intelectual e industrial del Sitio Web, as&iacute; como de los derechos de propiedad
                        intelectual e industrial sobre la informaci&oacute;n, materiales y contenidos de la misma.</p>
                    <p>En ning&uacute;n caso se entender&aacute; que el acceso, navegaci&oacute;n y utilizaci&oacute;n
                        del Sitio Web por parte del usuario implica una renuncia, transmisi&oacute;n, licencia o
                        cesi&oacute;n total o parcial de dichos derechos por parte de<strong>&nbsp;FUNDACI&Oacute;N
                            VILLACISNEROS y FUNDACI&Oacute;N CEU.&nbsp;</strong>El usuario dispone de un derecho de uso
                        de los contenidos y/o servicios del Sitio Web dentro de un &aacute;mbito estrictamente
                        dom&eacute;stico.</p>
                    <p>Las referencias a marcas o nombres comerciales registrados, u otros signos distintivos, ya sean
                        titularidad de<strong>&nbsp;FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>o
                        de terceras empresas, llevan impl&iacute;citas la prohibici&oacute;n sobre su uso sin el
                        consentimiento de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>o
                        de sus leg&iacute;timos titulares. En ning&uacute;n momento, salvo manifestaci&oacute;n expresa
                        en contrario, el acceso, navegaci&oacute;n o utilizaci&oacute;n del Sitio Web y/o de sus
                        contenidos, confiere al usuario derecho alguno sobre signos distintivos en &eacute;l incluidos.
                    </p>
                    <p>Quedan reservados todos los derechos de propiedad intelectual e industrial sobre los contenidos
                        y/o servicios del Sitio Web y, en particular, queda prohibido modificar, copiar, reproducir,
                        comunicar p&uacute;blicamente, transformar o distribuir, por cualquier medio y bajo cualquier
                        forma, la totalidad o parte de los contenidos incluidos en la Web, para prop&oacute;sitos
                        p&uacute;blicos o comerciales, si no se cuenta con la autorizaci&oacute;n previa, expresa y por
                        escrito de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>o, en su
                        caso, del titular de los derechos correspondientes.</p>
                    <p>En el caso de que el usuario env&iacute;e informaci&oacute;n de cualquier tipo
                        a <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>a trav&eacute;s
                        cualquiera de los canales habilitados al efecto, el usuario declara, garantiza y acepta que
                        tiene derecho a hacerlo libremente, que dicha informaci&oacute;n no infringe ning&uacute;n
                        derecho de propiedad intelectual, industrial, secreto comercial o cualesquiera otros derechos de
                        terceros, y que dicha informaci&oacute;n no tiene car&aacute;cter confidencial ni es perjudicial
                        para terceros.</p>
                    <p>El usuario reconoce asumir la responsabilidad, dejando indemne a <strong>FUNDACI&Oacute;N
                        VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>por cualquier comunicaci&oacute;n que
                        suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricci&oacute;n
                        alguna la exactitud, legalidad, originalidad y titularidad de la misma.</p>
                    <p><strong>MARCAS</strong></p>
                    <p>Las marcas, logotipos y cualesquiera otros de propiedad industrial que aparecen en este sitio son
                        propiedad de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> o de
                        terceras partes. Est&aacute; prohibida la utilizaci&oacute;n de estas marcas sin la previa
                        autorizaci&oacute;n escrita de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N
                            CEU</strong>, o de las terceras partes propietarias de las marcas.</p>
                    <p><strong>ENLACES</strong></p>
                    <p><strong>ENLACES A OTRAS P&Aacute;GINAS WEB</strong></p>
                    <p>En caso de que en el Sitio Web se mostraran enlaces a otras p&aacute;ginas web mediante
                        diferentes botones, links, banners o contenidos embebidos, <strong>FUNDACI&Oacute;N
                            VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> informa que &eacute;stos se encuentran
                        directamente gestionados por terceros, no teniendo <strong>FUNDACI&Oacute;N VILLACISNEROS y
                            FUNDACI&Oacute;N CEU</strong> ni medios humanos, ni t&eacute;cnicos para conocer de forma
                        previa y/o controlar y/o aprobar toda la informaci&oacute;n, contenidos, productos o servicios
                        facilitados por otros sitios web a los que se puedan establecer enlaces desde el presente Sitio
                        Web.</p>
                    <p>En consecuencia, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no
                        podr&aacute; asumir ning&uacute;n tipo de responsabilidad por cualquier aspecto relativo a la
                        p&aacute;gina web a la que se pudiera establecer un enlace desde el Sitio Web, en concreto, a
                        t&iacute;tulo enunciativo y no limitativo, sobre su funcionamiento, acceso, datos,
                        informaci&oacute;n, archivos, calidad y fiabilidad de sus productos y servicios, sus propios
                        enlaces y/o cualquiera de sus contenidos, en general.</p>
                    <p>En este sentido, si los Usuarios tuvieran conocimiento efectivo de que las actividades
                        desarrolladas a trav&eacute;s de estas p&aacute;ginas web de terceros son ilegales o
                        contravienen la moral y/o el orden p&uacute;blico, deber&aacute;n comunicarlo inmediatamente
                        a <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> a los efectos de que se
                        proceda a deshabilitar el enlace de acceso a las mismas, acci&oacute;n que se llevar&aacute; a
                        cabo en el menor tiempo posible.</p>
                    <p>En cualquier caso, el establecimiento de cualquier tipo de enlace desde el Sitio Web a otra
                        p&aacute;gina web ajena no implicar&aacute; que exista alg&uacute;n tipo de relaci&oacute;n,
                        colaboraci&oacute;n o dependencia entre <strong>FUNDACI&Oacute;N VILLACISNEROS y
                            FUNDACI&Oacute;N CEU</strong> y el responsable de la p&aacute;gina web ajena.</p>
                    <p><strong>ENLACES DE TERCEROS</strong></p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> ponen a disposici&oacute;n
                        de los usuarios, a trav&eacute;s de diferentes herramientas y aplicaciones, medios de enlace que
                        permiten a los usuarios acceder a canales y p&aacute;ginas en diferentes webs. La
                        instalaci&oacute;n de estas aplicaciones en el Sitio Web tiene por &uacute;nico objeto facilitar
                        a los usuarios el acceso a dichos canales en las diferentes plataformas y redes sociales.</p>
                    <p>El establecimiento de estas aplicaciones no implica la existencia de relaci&oacute;n alguna
                        entre <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> y el propietario,
                        fabricante o distribuidor de la plataforma enlazada, como tampoco la aceptaci&oacute;n y
                        aprobaci&oacute;n por parte de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N
                            CEU</strong> de sus contenidos y/o servicios, siendo su propietario, fabricante o
                        distribuidor el &uacute;nico responsable de los mismos.</p>
                    <p>En ning&uacute;n caso, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N
                        CEU</strong> comparte con tales propietarios de tales p&aacute;ginas webs externas,
                        ning&uacute;n tipo de informaci&oacute;n privada sobre sus usuarios, siendo su &uacute;nica
                        finalidad facilitar el acceso a ellas por parte de los usuarios. En este sentido, toda la
                        informaci&oacute;n que el propio usuario desee proporcionar a estas plataformas y/o
                        p&aacute;ginas webs externas, ser&aacute; bajo su propia responsabilidad, no interviniendo en
                        dicho proceso <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong>.</p>
                    <p>Debido a que <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no tiene
                        control alguno sobre el contenido alojado en dichos canales, el usuario reconoce y acepta
                        que <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no asume
                        responsabilidad alguna por el contenido ni por los servicios a los que el usuario pueda acceder
                        en dichas p&aacute;ginas ni por cualquier contenido, productos, servicios, publicidad, ni
                        cualquier otro material disponible en los mismos. Por tal motivo, el usuario debe extremar la
                        prudencia en la valoraci&oacute;n y utilizaci&oacute;n de la informaci&oacute;n, contenidos y
                        servicios existentes en los canales enlazados, y sobre la informaci&oacute;n propia o de
                        terceros que quiera compartir en dichos canales.</p>
                    <p><strong>ENLACES EN OTRAS P&Aacute;GINAS WEB CON DESTINO AL SITIO WEB</strong></p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no autoriza el
                        establecimiento de un enlace al presente Sitio Web desde otras p&aacute;ginas web que contengan
                        materiales, informaci&oacute;n o contenidos il&iacute;citos, ilegales, degradantes, obscenos y,
                        en general, que contravengan las leyes, la moral o al orden p&uacute;blico, o las normas
                        sociales generalmente aceptadas.</p>
                    <p>En todo caso, los usuarios podr&aacute;n establecer enlaces en sus respectivas p&aacute;ginas web
                        siempre y cuando soliciten la autorizaci&oacute;n previa y expresa a <strong>FUNDACI&Oacute;N
                            VILLACISNEROS y FUNDACI&Oacute;N CEU</strong>.</p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no tiene facultad ni
                        medios humanos y t&eacute;cnicos para conocer, controlar ni aprobar toda la informaci&oacute;n,
                        contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos
                        enlaces con destino al presente Sitio Web. <strong>FUNDACI&Oacute;N VILLACISNEROS y
                            FUNDACI&Oacute;N CEU</strong> no asume ning&uacute;n tipo de responsabilidad por cualquier
                        aspecto relativo a las p&aacute;ginas web que establecen ese enlace con destino al presente
                        Sitio Web, en concreto, a t&iacute;tulo enunciativo y no taxativo, sobre su funcionamiento,
                        acceso, datos, informaci&oacute;n, archivos, calidad y fiabilidad de sus productos y servicios,
                        sus propios enlaces y/o cualquiera de sus contenidos, en general.</p>
                    <p><strong>CONDICIONES DE USO DEL SITIO WEB</strong></p>
                    <p>No est&aacute; permitido y, por tanto, sus consecuencias ser&aacute;n de la exclusiva
                        responsabilidad del usuario, el acceso o la utilizaci&oacute;n del Sitio Web con fines ilegales
                        o no autorizados, con o sin finalidad econ&oacute;mica, y, m&aacute;s espec&iacute;ficamente y
                        sin que el siguiente listado tenga car&aacute;cter absoluto, queda prohibido:</p>
                    <p>1. Usar el Sitio Web en forma alguna que pueda provocar da&ntilde;os, interrupciones,
                        ineficiencias o defectos en su funcionalidad o en el ordenador de un tercero;</p>
                    <p>2. Usar el Sitio Web para la transmisi&oacute;n, la instalaci&oacute;n o la publicaci&oacute;n de
                        cualquier virus, c&oacute;digo malicioso u otros programas o archivos perjudiciales;</p>
                    <p>3. Usar el Sitio Web para recoger datos de car&aacute;cter personal de otros usuarios;</p>
                    <p>4. Registrarse a trav&eacute;s del Sitio Web con una identidad falsa, suplantado a terceros o
                        utilizando un perfil o realizando cualquier otra acci&oacute;n que pueda confundir a otros
                        usuarios sobre la identidad del origen de un mensaje;</p>
                    <p>5. Acceder sin autorizaci&oacute;n a cualquier secci&oacute;n del Sitio Web, a otros sistemas o
                        redes conectadas a la Plataforma, a ning&uacute;n servidor de <strong>FUNDACI&Oacute;N
                            VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> ni a los servicios ofrecidos a trav&eacute;s
                        del Sitio Web, por medio de pirateo o falsificaci&oacute;n, extracci&oacute;n de
                        contrase&ntilde;as o cualquier otro medio ileg&iacute;timo;</p>
                    <p>6. Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticaci&oacute;n del Sitio
                        Web o de cualquier red conectada a la misma, o las medidas de seguridad o protecci&oacute;n
                        inherentes a los contenidos ofrecidos en el Sitio Web;</p>
                    <p>7. Llevar a cabo alguna acci&oacute;n que provoque una saturaci&oacute;n desproporcionada o
                        innecesaria en la infraestructura del Sitio Web o en los sistemas o redes
                        de <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong>, as&iacute; como en
                        los sistemas y redes conectados al Sitio Web; o</p>
                    <p>8. Impedir el normal desarrollo de un evento, concurso, promoci&oacute;n o cualquier otra
                        actividad disponible a trav&eacute;s del Sitio Web o cualesquiera de sus funcionalidades, ya sea
                        alterando o tratando de alterar, ilegalmente o de cualquier otra forma, el acceso,
                        participaci&oacute;n o funcionamiento de aqu&eacute;llos, o falseando el resultado de los mismos
                        y/o utilizando m&eacute;todos de participaci&oacute;n fraudulentos, mediante cualquier
                        procedimiento, y/o a trav&eacute;s de cualquier pr&aacute;ctica que atente o vulnere en modo
                        alguno las presentes Condiciones de uso.</p>
                    <p>El incumplimiento de cualquiera de las anteriores obligaciones por el usuario,
                        podr&aacute; llevar aparejada la adopci&oacute;n por <strong>FUNDACI&Oacute;N VILLACISNEROS y
                            FUNDACI&Oacute;N CEU</strong> de las medidas oportunas amparadas en Derecho y en el
                        ejercicio de sus derechos u obligaciones, pudiendo llegar a la eliminaci&oacute;n o bloqueo de
                        la cuenta del usuario infractor, sin que medie posibilidad de indemnizaci&oacute;n alguna por
                        los da&ntilde;os y perjuicios causados.</p>
                    <p><strong>RESPONSABILIDADES Y GARANT&Iacute;AS.</strong></p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no puede garantizar la
                        fiabilidad, utilidad o veracidad de absolutamente toda la informaci&oacute;n y/o de los
                        servicios del Sitio Web, ni tampoco de la utilidad o veracidad de la documentaci&oacute;n puesta
                        a disposici&oacute;n a trav&eacute;s de la misma.</p>
                    <p>En consecuencia, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no
                        garantiza ni se hace responsable de: (i) la continuidad de los contenidos del Sitio Web; (ii) la
                        ausencia de errores en dichos contenidos; (iii) la ausencia de virus y/o dem&aacute;s
                        componentes da&ntilde;inos en el Sitio Web o en el servidor que lo suministra; (iv) la
                        invulnerabilidad del Sitio Web y/o la imposibilidad de vulnerar las medidas de seguridad que se
                        adopten en la misma; (v) la falta de utilidad o rendimiento de los contenidos del Sitio Web; y
                        (vi) los da&ntilde;os o perjuicios que cause, a s&iacute; mismo o a un tercero, cualquier
                        persona que infringiera las condiciones, normas e instrucciones que <strong>FUNDACI&Oacute;N
                            VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> establece en el Sitio Web o a trav&eacute;s de
                        la vulneraci&oacute;n de los sistemas de seguridad del Sitio Web.</p>
                    <p>No obstante, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> declara que
                        ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la
                        t&eacute;cnica, para garantizar el funcionamiento del Sitio Web y reducir al m&iacute;nimo los
                        errores del sistema, tanto desde el punto de vista t&eacute;cnico, como de los contenidos
                        publicados en el Sitio Web.</p>
                    <p>Si el usuario tuviera conocimiento de la existencia de alg&uacute;n contenido il&iacute;cito,
                        ilegal, contrario a las leyes o que pudiera suponer una infracci&oacute;n de derechos de
                        propiedad intelectual o industrial, de la normativa aplicable en material de protecci&oacute;n
                        de datos personales y/o cualquier otro derecho, deber&aacute; notificarlo inmediatamente
                        a <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> para que &eacute;stos
                        puedan proceder a la adopci&oacute;n de las medidas oportunas.</p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no garantizan la licitud,
                        fiabilidad y utilidad de los contenidos suministrados por terceros a trav&eacute;s del Sitio
                        Web.</p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no ser&aacute;n
                        responsables sobre la veracidad, integridad o actualizaci&oacute;n de las informaciones
                        publicadas en el Sitio Web provenientes de fuentes ajenas al mismo (noticias externas, informes
                        de profesionales externos, etc.), as&iacute; como tampoco de las contenidas en otros portales
                        web mediante enlace desde el Sitio Web.</p>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> no asumir&aacute;n
                        responsabilidad en cuanto a hipot&eacute;ticos perjuicios que pudieran originarse por el uso de
                        las citadas informaciones.</p>
                    <p>En todo caso, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong> se reservan
                        el derecho a suspender, modificar, restringir o interrumpir, temporal o permanentemente, el
                        acceso, navegaci&oacute;n, uso, alojamiento y/o descarga del contenido y/o uso de servicios del
                        Sitio Web, con o sin previa notificaci&oacute;n, a los usuarios que contravengan cualquiera de
                        las disposiciones detalladas en las presentes condiciones de uso, sin que medie posibilidad del
                        usuario de exigir indemnizaci&oacute;n alguna por esta causa.</p>
                    <p><strong>POL&Iacute;TICA DE PRIVACIDAD</strong></p>
                    <p>De conformidad con lo dispuesto por la legislaci&oacute;n vigente y aplicable en materia de
                        protecci&oacute;n de datos de car&aacute;cter personal, todos los datos de car&aacute;cter
                        personal facilitados durante la utilizaci&oacute;n del Sitio Web ser&aacute;n tratados de
                        conformidad con los dispuesto en la Pol&iacute;tica de Privacidad que todo usuario debe aceptar
                        expresamente para poder utilizar y registrarse en el Sitio Web.</p>
                    <p>Todo usuario que acepte las presentes condiciones de uso, acepta de forma informada, expresa e
                        inequ&iacute;voca nuestra Pol&iacute;tica de Privacidad, pudiendo ejercer los derechos que en
                        esta materia le corresponden, seg&uacute;n se informa en la mencionada Pol&iacute;tica de
                        Privacidad.</p>
                    <p><strong>LEY APLICABLE Y JURISDICCI&Oacute;N COMPETENTE</strong></p>
                    <p>Estas Condiciones de Uso se rigen por la ley espa&ntilde;ola. Las partes se someten expresamente,
                        para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los
                        juzgados y tribunales de Madrid capital.</p>
                    <p>Para presentar reclamaciones en el uso de nuestros servicios el cliente puede dirigirse por
                        correo a la direcci&oacute;n electr&oacute;nica o f&iacute;sica indicada en el
                        apartado &ldquo;Identificaci&oacute;n&rdquo;, comprometi&eacute;ndonos a buscar en todo momento
                        una soluci&oacute;n amistosa del conflicto.</p>
                    <p>&Uacute;ltima actualizaci&oacute;n: 7 de abril 2022</p>
                    <p>&copy; 2022 FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU Todos los derechos
                        reservados</p>
                    <p><strong>POL&Iacute;TICA DE COOKIES</strong></p>
                    <p>Esta Pol&iacute;tica de Cookies es parte integrante del Aviso Legal y la Pol&iacute;tica de
                        Privacidad de la p&aacute;gina web&nbsp;https://eduhispania.es/&nbsp;(en adelante, el &ldquo;
                        <strong>Sitio Web</strong>&rdquo;). El acceso y la navegaci&oacute;n en el Sitio Web, o el uso
                        de los servicios del mismo, implican la aceptaci&oacute;n de los t&eacute;rminos y condiciones
                        recogido en el Aviso Legal y en la Pol&iacute;tica de Privacidad.</p>
                    <p>Con el fin de facilitar la navegaci&oacute;n por el Sitio Web, <strong>FUNDACI&Oacute;N
                        VILLACISNEROS y FUNDACI&Oacute;N CEU</strong>, como cotitulares del mismo (en adelante, &ldquo;
                        <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU</strong>&rdquo;) con domicilio
                        social en C/ Montalb&aacute;n, 7 4&ordm; 28014. Madrid, con N.I.F. G-85139194, le comunica que
                        utiliza cookies u otros dispositivos de almacenamiento de almacenamiento y recuperaci&oacute;n
                        de datos de funcionalidad similar (en adelante, las &ldquo;<strong>Cookies</strong>&rdquo;).</p>
                    <p>Esta Pol&iacute;tica de Cookies es parte integrante del Aviso Legal y la Pol&iacute;tica de
                        Privacidad de la p&aacute;gina web <a
                            href="https://eduhispania.es/">https://eduhispania.es/</a>&nbsp; (en adelante, el &ldquo;
                        <strong>Sitio Web</strong>&rdquo;). El acceso y la navegaci&oacute;n en el Sitio Web, o el uso
                        de los servicios del mismo, implican la aceptaci&oacute;n de los t&eacute;rminos y condiciones
                        recogido en el Aviso Legal y en la Pol&iacute;tica de Privacidad.</p>
                    <p>En este sentido y con el objetivo de garantizar al usuario toda la informaci&oacute;n necesaria
                        para su correcta navegaci&oacute;n, ponemos a disposici&oacute;n del usuario el siguiente texto
                        informativo sobre qu&eacute; son las cookies, qu&eacute; tipolog&iacute;a de cookies existen en
                        nuestra P&aacute;gina Web y c&oacute;mo es posible configurarlas o deshabilitarlas.</p>
                    <ol>
                        <li><strong>&iquest;Qu&eacute; es una Cookie?</strong></li>
                    </ol>
                    <p>Las Cookies son archivos que contienen peque&ntilde;as cantidades de informaci&oacute;n que se
                        descargan en el dispositivo del usuario cuando visita una p&aacute;gina web. Su finalidad
                        principal es reconocer al usuario cada vez que accede al Sitio Web permitiendo, adem&aacute;s,
                        mejorar la calidad y ofrecer un mejor uso del Sitio Web.</p>
                    <p>Las Cookies son esenciales para el funcionamiento de Internet, ya que ayudan, entre otras
                        funciones, a identificar y resolver posibles errores de funcionamiento del Sitio Web.</p>
                    <ol>
                        <li><strong>Tipolog&iacute;a de cookies</strong></li>
                    </ol>
                    <p>En el presente Sitio Web se utilizan cookies propias y de terceros. Las cookies utilizadas se
                        almacenan en su ordenador durante un tiempo determinado, &eacute;stas pueden considerarse
                        cookies de sesi&oacute;n (si caducan cuando abandona nuestro Sitio Web) o persistentes (si la
                        caducidad de las cookies dura m&aacute;s tiempo). Las cookies utilizadas en la presente
                        p&aacute;gina Web son:</p>
                    <p><strong>Cookies &ldquo;estrictamente necesarias&rdquo;</strong></p>
                    <p>Son aquellas cookies consideradas imprescindibles para la navegaci&oacute;n por la P&aacute;gina
                        Web, pues facilitan al usuario la utilizaci&oacute;n de sus prestaciones o herramientas como,
                        por ejemplo, identificar la sesi&oacute;n, acceder a partes de acceso restringido, recordar los
                        elementos que integran un pedido, realizar el proceso de compra de un pedido, entre otros. Sin
                        estas cookies, las funciones mencionadas quedar&iacute;an inhabilitadas.</p>
                    <p><strong>Cookies sobre comportamiento</strong></p>
                    <p>Son aquellas cookies cuyo objetivo es la recopilaci&oacute;n de informaci&oacute;n relativa a los
                        usos de navegaci&oacute;n del usuario, como por ejemplo las p&aacute;ginas que son m&aacute;s
                        visitadas, los mensajes de error, entre otros, con el objetivo por parte del responsable, de
                        introducir mejoras en la p&aacute;gina web en base a la informaci&oacute;n recopilada por estas
                        cookies.</p>
                    <p>La informaci&oacute;n recopilada por estas cookies es an&oacute;nima no pudiendo identificar al
                        usuario personalmente y, por lo tanto, se utiliza exclusivamente para el correcto funcionamiento
                        de la p&aacute;gina web.</p>
                    <p><strong>Cookies de funcionalidad</strong></p>
                    <p>Son aquellas cookies que permiten recordar a la p&aacute;gina web las decisiones que el usuario
                        toma, por ejemplo: se almacenar&aacute; la ubicaci&oacute;n geogr&aacute;fica del usuario para
                        asegurar que se muestra el sitio web indicado para su regi&oacute;n, el nombre de usuario, el
                        idioma, el tipo de navegador mediante el cual se accede a la p&aacute;gina web, entre otros. El
                        objetivo es la prestaci&oacute;n de un servicio m&aacute;s personalizado.</p>
                    <p>La informaci&oacute;n recopilada por estas cookies es an&oacute;nima no pudiendo identificar al
                        usuario personalmente y, por lo tanto, se utiliza exclusivamente para el funcionamiento de la
                        p&aacute;gina web.</p>
                    <p><strong>Cookies de personalizaci&oacute;n y de publicidad</strong></p>
                    <p>Son aquellas cookies que se utilizan por los operadores publicitarios con el permiso del titular
                        de la p&aacute;gina web, las cuales recogen informaci&oacute;n sobre las preferencias y
                        elecciones del usuario en su navegaci&oacute;n por sitios web. Se dirigen a las redes de
                        publicidad quienes las utilizan para luego mostrar al usuario anuncios personalizados en otros
                        sitios web.</p>
                    <ol>
                        <li><strong>Cuadro resumen de cookies utilizadas en el Sitio Web</strong></li>
                    </ol>
                    <p><strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>utiliza cookies para
                        personalizar la navegaci&oacute;n del usuario por los Sitios Web de su titularidad, cookies de
                        entrada de usuario y cookies de sesi&oacute;n para equilibrar la carga, las cuales est&aacute;n
                        excluidas del &aacute;mbito de aplicaci&oacute;n del art&iacute;culo 22.2 de la LSSI.</p>
                    <p>Por otra parte, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>utiliza
                        cookies de anal&iacute;tica web para medir y analizar la navegaci&oacute;n de los usuarios en
                        los Sitios Web. <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N CEU&nbsp;</strong>utiliza
                        los datos obtenidos con el fin de introducir mejoras en funci&oacute;n del an&aacute;lisis de
                        los datos de uso que hacen los usuarios. La anal&iacute;tica web no permite obtener
                        informaci&oacute;n sobre el nombre, apellidos o direcci&oacute;n de correo electr&oacute;nico o
                        postal del usuario. La informaci&oacute;n obtenida es la relativa al n&uacute;mero de usuarios
                        que acceden a la web, el n&uacute;mero de p&aacute;ginas vistas, la frecuencia y
                        repetici&oacute;n de las visitas, su duraci&oacute;n, el navegador utilizado, el operador que
                        presta el servicio, el idioma, el terminal utilizado o la ciudad a la que est&aacute; asignada
                        la direcci&oacute;n IP.</p>
                    <p><br />Del mismo modo, <strong>FUNDACI&Oacute;N VILLACISNEROS y FUNDACI&Oacute;N
                        CEU</strong> utiliza cookies de publicidad comportamental para la gesti&oacute;n de los espacios
                        publicitarios en base a criterios determinados y cookies de complemento (plug-in) para
                        intercambiar contenidos sociales. Estas cookies almacenan informaci&oacute;n del comportamiento
                        de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus
                        h&aacute;bitos de navegaci&oacute;n, lo que permite mostrar publicidad en funci&oacute;n del
                        mismo.
                    </p>
                    <div className="table-wrapper">
                        <table cellPadding="0" cellSpacing="0" >
                            <tbody>
                            <tr>
                                <td valign="middle">
                                    <p><strong>NOMBRE</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>TITULAR</p>
                                </td>
                                <td valign="middle">
                                    <p>FINALIDAD</p>
                                </td>
                                <td valign="middle">
                                    <p>DURACI&Oacute;N</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>_ga</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Google</p>
                                </td>
                                <td valign="middle">
                                    <p>Esta cookie de origen contiene un identificador an&oacute;nimo usado para distinguir
                                        usuarios</p>
                                </td>
                                <td valign="middle">
                                    <p>2 a&ntilde;os o actualizaci&oacute;n</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>_gat</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Google</p>
                                </td>
                                <td valign="middle">
                                    <p>Esta cookie de origen contiene un identificador para diferenciar entre los diferentes
                                        objetos de seguimiento creados en la sesi&oacute;n</p>
                                </td>
                                <td valign="middle">
                                    <p>10 minutos desde creaci&oacute;n o modificaci&oacute;n</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>_gid</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Google</p>
                                </td>
                                <td valign="middle">
                                    <p>Esta cookie se usa para distinguir usuarios</p>
                                </td>
                                <td valign="middle">
                                    <p>24 horas</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>NID</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Google</p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>SIDCC</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Google</p>
                                </td>
                                <td valign="middle">
                                    <p><br/></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>G_ENABLED_IDPS</strong></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>io</strong></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>planlector-user-lang</strong></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>OCAK</strong></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>GAPS</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Youtube</p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>VISITOR_INFO1_LIVE</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Youtube</p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>YSC</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Youtube</p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <p><strong>GAPS</strong></p>
                                </td>
                                <td valign="middle">
                                    <p>Youtube</p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                                <td valign="middle">
                                    <p><br /></p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <p><br /></p>
                    <ol>
                        <li><strong>Configuraci&oacute;n del usuario para evitar Cookies</strong></li>
                    </ol>
                    <p>En cumplimiento de la normativa legal vigente, <strong>FUNDACI&Oacute;N VILLACISNEROS y
                        FUNDACI&Oacute;N CEU</strong> pone a disposici&oacute;n de los usuarios del Sitio Web la
                        informaci&oacute;n que les permita configurar su navegador/navegadores de Internet para mantener
                        su privacidad y seguridad en relaci&oacute;n con las Cookies. Por ello, se facilita la
                        informaci&oacute;n y los enlaces a los sitios de soporte oficiales de los principales
                        navegadores para que cada usuario pueda decidir si desea o no aceptar el uso de Cookies.</p>
                    <p>As&iacute;, es posible bloquear las Cookies a trav&eacute;s de las herramientas de
                        configuraci&oacute;n del navegador, o bien, cada usuario puede configurar su navegador para que
                        le avise cuando un servidor quiera guardar una Cookie:</p>
                    <ol>
                        <li>Si utiliza Microsoft Internet Explorer, encontrar&aacute; la opci&oacute;n en el
                            men&uacute; Herramientas &gt; Opciones de
                            Internet &gt; Privacidad &gt; Configuraci&oacute;n. Para saber m&aacute;s visite
                            http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies y&nbsp;<a
                                href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.
                        </li>
                        <li>Si utiliza Firefox, encontrar&aacute; la opci&oacute;n en el
                            men&uacute; Herramientas &gt; Opciones &gt; Privacidad &gt; Cookies. Para saber m&aacute;s
                            visite&nbsp;<a
                                href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.
                        </li>
                        <li>Si utiliza Chrome, en la secci&oacute;n de Opciones &gt; Opciones avanzadas &gt; Privacidad.
                            Para saber m&aacute;s&nbsp;<a
                                href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a> &nbsp;
                        </li>
                        <li>Si utiliza Opera, en la opci&oacute;n de Seguridad y Privacidad, podr&aacute; configurar el
                            navegador. Para saber m&aacute;s visite <a
                                href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
                        </li>
                        <li>Si utiliza Safari encontrar&aacute; la opci&oacute;n en el
                            men&uacute; Preferencias/Privacidad. M&aacute;s informaci&oacute;n en:&nbsp;<a
                                href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a> &nbsp;
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Legal