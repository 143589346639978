import {animated, useSpring} from "react-spring";
import './hamburgerBtn.scss'

const defaultMiddle = 'translateX(0%)'
const defaultConner = 'rotate(0deg) translate(0px, 0px)'

const HamburgerBtn = ({onclick, pressed}) => {

    const goLeft = useSpring({
        opacity: pressed ? 0 : 1,
        transform: pressed ? 'translateX(-100%)' : defaultMiddle
    })

    const goRight = useSpring({
        opacity: pressed ? 0 : 1,
        transform: pressed ? 'translateX(100%)' : defaultMiddle
    })

    const topLeft = useSpring({
        transform: pressed ? 'rotate(45deg) translate(5px, 1.25px)' : defaultConner
    })

    const topRight = useSpring({
        transform: pressed ? 'rotate(-45deg) translate(-5px, 1.25px)' : defaultConner
    })

    const bottomLeft = useSpring({
        transform: pressed ? 'rotate(-45deg) translate(5px, -1.25px)' : defaultConner
    })

    const bottomRight = useSpring({
        transform: pressed ? 'rotate(45deg) translate(-5px, -1.25px)' : defaultConner
    })

    return <div
        className={'hamburger-btn-main-styles'}
        onClick={() => onclick(!pressed)}
    >
        <div>
            <animated.div style={topLeft} /><animated.div style={topRight} />
        </div>
        <div>
            <animated.div style={goLeft} /><animated.div style={goRight} />
        </div>
        <div>
            <animated.div style={bottomLeft} /><animated.div style={bottomRight} />
        </div>
    </div>
}

export default HamburgerBtn