import {Fragment, useEffect, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Images from "../../images";
import Person1 from "../../photos/img.png";
import Person2 from "../../photos/img_1.png";
import Person3 from "../../photos/img_2.png";
import Person4 from "../../photos/Cristina Munoz.jpg";
import Person5 from "../../photos/IMG_20211114_214718.jpg";
import {PostCall} from "../../utils/connection";
import {useParams} from "react-router-dom";

const gameLink = 'https://app.eduhispania.es'

const cards = [
    {
        img: Person1,
        name: 'Laura Ruiz Panalés',
        school: 'Colegio CEU San Pablo de Murcia',
        plus: [
            'Docente de Geografía e Historia',
            'Licenciada en Geografía e Historia por la Universidad Autónoma de Madrid.'
        ],
    },
    {
        img: Person2,
        name: 'Luz Pire Galiana',
        school: 'Colegio CEU Jesús María Alicante.',
        plus: [
            'Docente de Geografía e Historia',
            'Licenciada en Geografía e Historia por la Universidad Autónoma de Madrid.'
        ],
    },
    {
        img: Person3,
        name: 'Carlos Bonete Vizcaíno',
        school: 'Colegio CEU San Pablo Sanchinarro',
        plus: [
            'Docente de Geografía e Historia',
            'Historia, Historia del Arte e Interpretación musical'
        ],
    },
    {
        img: Person4,
        name: 'Cristina Muñoz-Delgado de Mata',
        school: 'Colegio CEU San Pablo Montepríncipe',
        plus: [
            'Dra. en Historia Antigua por la Universidad Autónoma de Madrid.',
            'Máster en Historia y Ciencias de la Antigüedad.',
            'Grado en Historia y en Historia del Arte'
        ],
    },
    {
        img: Person5,
        name: 'Paula Álvarez Sánchez',
        school: 'Colegio CEU San Pablo Valencia',
        plus: [
            'Licenciada en Geografía e Historia por la Universitat de València.',
            'Docente de Geografía e Historia.',
        ],
    },
]

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    arrows: false,
    appendDots: dots => (
        <div
            style={{
                position: 'relative',
                bottom: 0,
                marginBottom: 20
            }}
        >
            <ul style={{ margin: "0px", padding: 0 }}> {dots} </ul>
        </div>
    ),
    customPaging: () => (
        <div
            style={{
                width: 10,
                background: "white",
                borderRadius: '50%',
                height: 10
            }}
        />
    )
}

const defaultSate = {value: '', error: false}

const Home = () => {
    const { id } = useParams();
    const [open, setOpen] = useState(id === 'contact')
    const [slidesToShow, setSlidesToShow] = useState(4);
    const [validateContactInfo, setValidateContactInfo] = useState(false)
    const [contactName, setContactName] = useState(defaultSate)
    const [contactEmail, setContactEmail] = useState(defaultSate)
    const [desc, setDesc] = useState(defaultSate)

    const postContact = () => {
        setValidateContactInfo(true);
        if (!(contactName.error || contactEmail.error || desc.error)) {
            console.log('here')
            PostCall({path: 'contacto', data: {
                    txtNombre: contactName.value,
                    txtEmail: contactEmail.value,
                    txtMensaje: desc.value
                }}).then(res => {
                const {success} = res;
                if (success) {
                    setOpen(false);
                }
            }).finally(() => {
                setContactEmail(defaultSate);
                setDesc(defaultSate);
                setContactName(defaultSate);
            })
        }
    }

    useEffect(() => {
        const updateWindowDimensions = () => {
            if (window.innerWidth < 850 && window.innerHeight < 450) {
                setSlidesToShow(1)
            } else if  (window.innerWidth < 750) {
                setSlidesToShow(1)
            } else  {
                setSlidesToShow(4)
            }

        }
        window.addEventListener('resize', updateWindowDimensions)
        return () => window.removeEventListener('resize', updateWindowDimensions)
    }, [])

    return <Fragment>
        <Header
            menu={[
                <Button label={'Contacto'} ghost onPress={() => setOpen(true)} />,
                <Button label={'Registro'} ghost href={`${gameLink}/StartScreen`}/>,
                <Button href={`${gameLink}/LoginScreen`} label={'INICIA SESIÓN'} rounded />
            ]}
        />
        <div className="content">
            <div className="container">
                <div>
                    <div>
                        <h1>
                            El juego de Historia para Secundaria
                        </h1>
                        <p>
                            EduHispania es la plataforma para enseñar Historia de una forma más lúdica y motivadora para tu alumnado.
                        </p>
                        <Button label={'Pruébalo 15 días GRATIS'} href={`${gameLink}/StartScreen`} />
                    </div>
                    <div>
                        <img src={Images.Platforms} alt={'poster'} />
                    </div>
                </div>
            </div>

            <div className="banner-area">
                <div>
                    <div>
                        <img src={Images.Banner} alt={'poster'} />
                    </div>
                    <div>
                        <h2>
                            ¡Atrévete a viajar en el tiempo!
                        </h2>
                        <p>
                            Conviértete en <b>Custodio de la Real Cámara de las Reliquias</b> y sumérgete en la aventura de viajar a distintas épocas históricas para recuperar <b>tesoros perdidos en el tiempo</b>.
                        </p>
                    </div>
                </div>
            </div>

            <div className="back-and-forth-text">
                <div>
                    <h1>
                        EduHispania en el aula
                    </h1>
                    <div>
                        <div>
                            <img src={Images.Maps} alt="banner"/>
                        </div>
                        <div>
                            <h2>Actividades adaptadas al currículo para reforzar lo que les enseñas en clase</h2>
                            <p>
                                Tu alumnado tendrá que superar retos educativos cuyo contenido ha sido diseñado por docentes de Historia, ajustándose al programa oficial.
                            </p>
                        </div>
                    </div>
                    <div className={'reverse'}>
                        <div>
                            <img src={Images.Tablet} alt="banner"/>
                        </div>
                        <div>
                            <h2>Motiva a tu alumnado con dinámicas de juego</h2>
                            <p>
                                Añade a tus clases elementos como niveles, avatares, duelos entre compañeros o ligas intercolegiales para despertar su entusiasmo por la Historia.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={Images.Laptop} alt="banner"/>
                        </div>
                        <div>
                            <h2>Comprueba la evolución de tu alumnado en tiempo real</h2>
                            <p>
                                Accede al panel de control donde podrás supervisar la actividad de cada alumno así como su rendimiento académico.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-area">
                <p>Contenidos de Historia creados por <b>docentes expertos</b>:</p>
                <div>
                    <Slider
                        className={'slider'}
                        {...settings}
                        slidesToShow={slidesToShow}
                    >
                        {
                            cards.map(({img, name, qualification, school, teaching, plus}, i) =>
                                <Card
                                    key={i}
                                    img={img}
                                    name={name}
                                    qualification={qualification}
                                    school={school}
                                    teaching={teaching}
                                    plus={plus}
                                />
                            )
                        }
                    </Slider>
                </div>
            </div>

            <div className="parallax-area">
                <div>
                    <div>
                        <h1>¿Quieres probarlo con tu alumnado? </h1>
                        <Button label={'Pruébalo 15 días GRATIS'} roundedq href={`${gameLink}/StartScreen`}/>
                    </div>
                    <div>
                        <img src={Images.Teachers} alt={'teachers'} />
                    </div>
                </div>
            </div>

            <div className="footer">
                <p><b>Este proyecto ha sido creado por:</b></p>
                <div>
                    <div>
                        <a href={'https://www.ceu.es/'} target={'_blank'} rel="noreferrer">
                            <img src={Images.CEU} alt="CEU"/>
                        </a>
                    </div>
                    <div>
                        <a href={'https://fundacionvillacisneros.es/'} target={'_blank'} rel="noreferrer">
                            <img src={Images.Foundation} alt="Foundation"/>
                        </a>
                    </div>
                </div>
            </div>

            <div className={'footer-footer'}>
                <div>
                    <div>© 2022 Fundación Villacisneros / Fundación CEU</div>
                    <div>
                        <Button label={'Aviso Legal'} ghost href={'https://eduhispania.es/legal'} />
                        <Button label={'Política de privacidad'} ghost href={'https://eduhispania.es/privacy'} />
                        <Button label={'Contacto'} ghost onPress={() => setOpen(true)} />
                    </div>
                </div>
            </div>
        </div>
        <Modal
            title={'Contacta con nosotros'}
            show={open}
            close={setOpen}
        >
            <form>
                <Input
                    name={'name'}
                    label={'Tu nombre y apellidos'}
                    value={contactName.value}
                    onChange={(value, error) => setContactName({value, error})}
                    validate={validateContactInfo}
                />
                <Input
                    name={'email'}
                    label={'Tu dirección de correo electrónico'}
                    value={contactEmail.value}
                    onChange={(value, error) => setContactEmail({value, error})}
                    validate={validateContactInfo}
                    type={'email'}
                />
                <Input
                    name={'desc'}
                    label={'¿En qué te podemos ayudar?'}
                    value={desc.value}
                    onChange={(value, error) => setDesc({value, error})}
                    validate={validateContactInfo}
                    type={'textarea'}
                />
                <div className={'contact-btn'}>
                    <Button
                        label={'Contactar'}
                        onPress={postContact}
                    />
                </div>
            </form>
        </Modal>
    </Fragment>
}

export default Home