import axios from 'axios';

const url = " https://sapi-hispania.academons.com";

export const PostCall = ({path, data}) => {
    let res = {
        success: false
    };

    return axios.post(`${url}/${path}`, data)
        .then(function (response) {
            const {errno} = response.data;
            if (errno === 0) {
                res.success = true;
            }
            return res;
        })
        .catch(function (error) {
            console.log(error);
            return res;
        })
}