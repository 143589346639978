import React, {useEffect, useState} from "react";
import './input.scss'

const EmailTest = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Input = ({name, label, value , onChange = () => {}, validate, type}) => {
    const [touched, setTouched] = useState(false);
    const [focused, setFocused] = useState(false);
    const [errorCond, setErrorCond] = useState(false);

    const textErrors = (touched, value) => {
        if (touched && !value) {
            return 'Rellena este campo obligatorio'
        }

        if (errorCond) {
            return 'Formato de email no válido';
        }

        return 'error';
    }

    const validationRules = (value) => {
        if (type === 'email') {
            return !EmailTest.test(String(value).toLowerCase())
        }

        return false
    }

    useEffect(() => {
        if (focused) {
            setErrorCond(false);
        }else if (touched && !value) {
            setErrorCond(true);
        }else if (touched && value) {
            if (type === 'email') {
                setErrorCond(!EmailTest.test(String(value).toLowerCase()))
            } else {
                setErrorCond(false);
            }

        } else {
            setErrorCond(false);
        }
    }, [focused, touched, value, type]);

    useEffect(() => {
        if (validate) {
            setTouched(true);
        }
    }, [validate])

    const props = {
        name,
        placeholder: label,
        value,
        onBlur: () => {setTouched(true); setFocused(false);},
        className: errorCond ? 'input-error' : '',
        onChange: e => onChange(e.target.value, validationRules(e.target.value)),
        onFocus: () => setFocused(true)
    }

    return <div>
        {
            type === 'textarea' ?
                <textarea {...props}/>
                :
                <input
                    {...props}
                />
        }
        <span className={errorCond ? 'error-msg' : ''}>
            {textErrors(touched, value)}
        </span>
    </div>
}

export default Input;