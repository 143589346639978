import {Routes, Route, BrowserRouter} from "react-router-dom";
import './app.scss'

import Home from "./containers/Home";
import Privacy from "./containers/Privacy";
import Legal from "./containers/Legal";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/:id" element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path={'/privacy'} exact element={<Privacy />}/>
                    <Route path={'/legal'} exact element={<Legal />}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
