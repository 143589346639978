import React from "react";
import { useSpring, animated } from 'react-spring';
import './modal.scss';

const Modal = ({show = true, title, close, children}) => {

    const props = useSpring({
        visibility: show ? 'visible' : 'hidden',
        opacity: show ? 1 : 0,
        config: {
            duration: 100
        }
    })

    return <animated.div style={props} className="modal-main" onClick={() => close && close(false)}>
        <div className="popup" onClick={(e) => e.stopPropagation()}>
            <h2>{title}</h2>
            {
                close &&
                <span className="close" onClick={() => close(false)}>&times;</span>
            }
            <div className="content">
                {children}
            </div>
        </div>
    </animated.div>
}

export default Modal;